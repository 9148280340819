<!-- <?xml version="1.0" encoding="utf-8"?> -->
<!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!-- <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"> -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 595 842" enable-background="new 0 0 595 842" xml:space="preserve">
<g id="logo">
	<path fill="#FFFFFF" stroke="#FFFFFF" d="M363.748,105.492h145.988v453.542H363.748
		C496.899,360.61,222.019,303.916,363.748,105.492z M56.193,105.492h148.819c-136.065,198.424,141.729,255.118,0,453.542H56.193
		V105.492z"/>
</g>
</svg>

