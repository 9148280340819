<svg version="1.1" viewBox="0 0 392.37 464.18" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(30.631 45.142)">
        <path d="m165.62-0.14215a223.06 222.88 0 0 0-85.208 16.903l20.492 20.492a196.54 196.54 0 0 1 50.194-10.404l2.5157 45.294a151.18 151.18 0 0 0-139.24 150.71 151.18 151.18 0 0 0 151.18 151.18 151.18 151.18 0 0 0 151.18-151.18 151.18 151.18 0 0 0-139.24-150.71l2.5157-45.294a196.54 196.54 0 0 1 50.194 10.406l20.532-20.532a223.06 222.88 0 0 0-85.12-16.865zm-0.0625 98.269a124.73 124.73 0 0 1 124.73 124.73 124.73 124.73 0 0 1-124.73 124.73 124.73 124.73 0 0 1-124.73-124.73 124.73 124.73 0 0 1 124.73-124.73z" stroke-width="5"/>
        <circle cx="0" cy="0" r="20">
            <animateMotion
                dur="2s"
                repeatCount="indefinite"
                path="m 265.23956,222.81595 a 99.779526,99.779526 0 0 1 -99.77952,99.77952 99.779526,99.779526 0 0 1 -99.779529,-99.77952 99.779526,99.779526 0 0 1 99.779529,-99.77953 99.779526,99.779526 0 0 1 99.77952,99.77953 z"
                keyPoints="0; 0.125; 0.25; 0.375; 0.5; 0.625; 0.75; 0.875; 1"
                keyTimes="0;0.075;0.130;0.170;0.285;0.450;0.700;0.870;1"
                calcMode="linear"
            />
        </circle>
    </g>
</svg>
